'use client';
import { Nav } from './Nav';
import navigationMenu from './navigation-menu';
import { cn } from '@/utils/utils';

const Sidebar = () => {
    return (
        <div className={cn('relative z-40 hidden min-h-screen bg-background sm:flex')}>
            <Nav links={navigationMenu} withCollapseBtn />
        </div>
    );
};

export default Sidebar;
